import * as Yup from "yup";

import {
  checkTruthyValue,
  INVALID_RESPONSE_MSG,
  nullableNumberValidation,
  percentValueValidation,
  VALIDATION_DATE,
} from "utils/validation";

const validation = Yup.object({
  purposeOfMortgage: Yup.string().required(INVALID_RESPONSE_MSG),

  depositAmount: Yup.string().required(INVALID_RESPONSE_MSG),

  firstChargeMortgageValue: Yup.string().required(INVALID_RESPONSE_MSG),

  valueOfFeeAddedToFirstChargeMortgage:
    Yup.string().required(INVALID_RESPONSE_MSG),

  equityLoanAmountRequired: Yup.string().required(INVALID_RESPONSE_MSG),

  propertyPurchasePrice: Yup.string().required(INVALID_RESPONSE_MSG),

  isFirstChargeARepaymentMortgage: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),

  firstChargeLender: Yup.string().required(INVALID_RESPONSE_MSG),

  termOfFirstChargeLoanYears: Yup.number()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG)
    .min(0, VALIDATION_DATE)
    .test(
      "total-duration-min",
      "Total term must be at least 5 years",
      function (value) {
        const months = this.parent.termOfFirstChargeLoanMonths || 0;
        const totalMonths = (value || 0) * 12 + months;
        return totalMonths >= 60; // 5 years in months
      },
    )
    .test(
      "total-duration-max",
      "Total term can not be more than 40 years",
      function (value) {
        const months = this.parent.termOfFirstChargeLoanMonths || 0;
        const totalMonths = (value || 0) * 12 + months;
        return totalMonths <= 480; // 40 years
      },
    ),

  termOfFirstChargeLoanMonths: Yup.number()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG)
    .max(11, VALIDATION_DATE),

  isInterestRateFixedForAnInitialPeriod: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),

  termOfInitialInterestRatePeriodYears: Yup.string().when(
    "isInterestRateFixedForAnInitialPeriod",
    {
      is: (value: any) => checkTruthyValue(value),
      then: () =>
        Yup.number()
          .typeError(INVALID_RESPONSE_MSG)
          .required(INVALID_RESPONSE_MSG),
      otherwise: () => nullableNumberValidation,
    },
  ),

  firstChargeInitialInterestRatePercent:
    percentValueValidation.required(INVALID_RESPONSE_MSG),

  firstChargeSvrPercent: percentValueValidation.required(INVALID_RESPONSE_MSG),

  equityLoanRepaymentPlan: Yup.string().required(INVALID_RESPONSE_MSG),

  sourceOfDeposit: Yup.string().required(INVALID_RESPONSE_MSG),

  isAllDepositByOwnResource: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),

  isAllOrPartDepositAGift: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),

  // isGiftFromCurrentOwner: Yup.string().when("isAllOrPartDepositAGift", {
  //   is: (value: any) => checkTruthyValue(value),
  //   then: () =>
  //     Yup.bool().typeError(INVALID_RESPONSE_MSG).required(INVALID_RESPONSE_MSG),
  //   otherwise: () => Yup.bool().nullable(),
  // }),

  // willGifterLiveInPropertyAfterPurchase: Yup.string().when(
  //   "isAllOrPartDepositAGift",
  //   {
  //     is: (value: any) => checkTruthyValue(value),
  //     then: () =>
  //       Yup.bool()
  //         .typeError(INVALID_RESPONSE_MSG)
  //         .required(INVALID_RESPONSE_MSG),
  //     otherwise: () => Yup.bool().nullable(),
  //   },
  // ),

  // willGifterTakeInterestInPropertyForGift: Yup.string().when(
  //   "isAllOrPartDepositAGift",
  //   {
  //     is: (value: any) => checkTruthyValue(value),
  //     then: () =>
  //       Yup.bool()
  //         .typeError(INVALID_RESPONSE_MSG)
  //         .required(INVALID_RESPONSE_MSG),
  //     otherwise: () => Yup.bool().nullable(),
  //   },
  // ),

  // amountOfGift: Yup.string().when("isAllOrPartDepositAGift", {
  //   is: (value: any) => checkTruthyValue(value),
  //   then: () => Yup.string().required(INVALID_RESPONSE_MSG),
  //   otherwise: () => Yup.string().nullable(),
  // }),

  // gifterDetail: Yup.string().when("isAllOrPartDepositAGift", {
  //   is: (value: any) => checkTruthyValue(value),
  //   then: () => Yup.string().required(INVALID_RESPONSE_MSG),
  //   otherwise: () => Yup.string().nullable(),
  // }),
}).nullable();

export default validation;
