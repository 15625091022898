import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { MUTATION_CREATE_ILLUSTRATION } from "api/mutations/illustration";
import CTAButton from "components/CTA/Button";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputNumber from "components/Form/Input/Number";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import FormTitle from "components/Form/Title";
import Loading from "components/Loading";
import PageContent from "components/Page/Content";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { useNavigate } from "react-router-dom";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";
import useIllustrationStore from "store/Illustration";
import { calcEquityLoanValue, inputToFloat } from "utils/calcs";
import { getQueryParams } from "utils/helpers";
import { ApplicantTitleHonorifics } from "utils/options";
import {
  default as DEFAULT_FORM_DATA,
  default as defaultData,
} from "./defaultData";
import { FormInputs } from "./types";
import { schema } from "./validation";

export default function CreateIllustration() {
  const queryParams = getQueryParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [
    createIllustration,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_CREATE_ILLUSTRATION);

  const { formData } = useIllustrationStore();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultData,
    reValidateMode: "onSubmit",
  });

  const propertyValue = watch("propertyValue");

  /**
   * If we have form data for the Illustration in the store, then pre-fill the form
   */
  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  useEffect(() => {
    const _equityLoanValue = calcEquityLoanValue(propertyValue);
    setValue("equityLoanValue", _equityLoanValue);
  }, [propertyValue, setValue]);

  useEffect(() => {
    if (mutationData) {
      setTimeout(() => {
        reset(DEFAULT_FORM_DATA);
        navigate(
          `${APP_URLS.CREATE_ILLUSTRATION}/${mutationData?.response?.illustration?.uuid}/complete`,
        );
      }, 1000);
    }
  }, [mutationData, mutationError, reset, navigate]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    setLoading(true);
    window.scrollTo(0, 0);

    if (queryParams?.case) {
      data.case = queryParams.case;
    }

    data.propertyValue = inputToFloat(data.propertyValue);
    data.equityLoanValue = inputToFloat(data.equityLoanValue);
    data.creditIntermediaryFees = inputToFloat(data.creditIntermediaryFees);

    createIllustration({
      variables: {
        input: data,
      },
    });
  };

  return (
    <PageContent title="Mortgage Illustration (ESIS)">
      <div className="mx-auto max-w-3xl space-y-5">
        <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
          <>
            {loading ? (
              <>
                <Loading />
                <p className="mt-4 text-center">Submitting Illustration...</p>
              </>
            ) : (
              <>
                {mutationError && (
                  <FormErrors
                    formErrors={{
                      "": {
                        message: `Can not create ESIS. Please correct the form errors and try again or contact our Support team on ${AHAUZ_PHONE_NUMBER}.`,
                      },
                    }}
                  />
                )}

                {errors && <FormErrors formErrors={errors} />}

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col space-y-6"
                  autoComplete="off"
                  noValidate
                >
                  <div className="flex flex-col space-y-4">
                    <FormTitle left border title="Applicant 1" mb={false} />

                    <FormInputSelect
                      id="applicant1Title"
                      label="Title"
                      register={register}
                      initSelect
                      options={ApplicantTitleHonorifics}
                      error={errors?.applicant1Title?.message}
                    />
                    <FormInputText
                      id="applicant1FirstName"
                      label="First name"
                      register={register}
                      error={errors?.applicant1FirstName?.message}
                    />
                    <FormInputText
                      id="applicant1LastName"
                      label="Last name"
                      register={register}
                      error={errors?.applicant1LastName?.message}
                    />
                  </div>

                  <div className="flex flex-col space-y-4">
                    <FormTitle
                      left
                      border
                      title="Applicant 2 (optional)"
                      mb={false}
                    />

                    <FormInputSelect
                      id="applicant2Title"
                      label="Title"
                      register={register}
                      initSelect
                      requiredField={false}
                      options={ApplicantTitleHonorifics}
                      error={errors?.applicant2Title?.message}
                    />
                    <FormInputText
                      id="applicant2FirstName"
                      label="First name"
                      register={register}
                      requiredField={false}
                      error={errors?.applicant2FirstName?.message}
                    />
                    <FormInputText
                      id="applicant2LastName"
                      label="Last name"
                      register={register}
                      requiredField={false}
                      error={errors?.applicant2LastName?.message}
                    />
                  </div>

                  <div className="flex flex-col space-y-4">
                    <FormTitle left border title="Loan" mb={false} />

                    <FormInputCurrency
                      id="propertyValue"
                      label="Property value"
                      register={register}
                      setValue={setValue}
                      error={errors?.propertyValue?.message}
                    />
                    <FormInputCurrency
                      id="equityLoanValue"
                      label="Equity loan value"
                      disabled
                      register={register}
                      setValue={setValue}
                      error={errors?.equityLoanValue?.message}
                      helperText="This value will be automatically calculated as 15% of the Property Value"
                    />
                    <div>
                      <span>Duration of the equity loan</span>
                      <span className="mb-1 block text-base text-brand-copy-lighter">
                        Please note that the Ahauz loan term will be one month
                        longer than the term of the first charge mortgage. For
                        example, if the first charge mortgage term is 30 years,
                        please enter 30 years and 1 month here for the Ahauz
                        loan term.
                      </span>
                      <div className="mt-1 flex w-full space-x-4">
                        <div className="w-1/2">
                          <FormInputNumber
                            id="equityLoanDurationYears"
                            label="Years"
                            register={register}
                            error={errors?.equityLoanDurationYears?.message}
                          />
                        </div>
                        <div className="w-1/2">
                          <FormInputNumber
                            id="equityLoanDurationMonths"
                            label="Months"
                            register={register}
                            error={errors?.equityLoanDurationMonths?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-4">
                    <FormTitle left border title="Fees" mb={false} />

                    <FormInputCurrency
                      id="creditIntermediaryFees"
                      label="Credit Intermediary fees"
                      register={register}
                      setValue={setValue}
                      error={errors?.creditIntermediaryFees?.message}
                    />
                  </div>

                  <nav className="!mt-8 flex justify-between border-t border-gray-200 pt-8">
                    <div></div>
                    <div className="flex space-x-2">
                      {mutationLoading ? (
                        <Loading />
                      ) : (
                        <CTAButton type="submit" label="Submit" />
                      )}
                    </div>
                  </nav>
                </form>
              </>
            )}
          </>
        </div>
      </div>
    </PageContent>
  );
}
