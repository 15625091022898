import { INVALID_RESPONSE_MSG, VALIDATION_DATE } from "utils/validation";
import * as yup from "yup";

export const schema = yup.object({
  applicant1Title: yup.string().required(INVALID_RESPONSE_MSG),
  applicant1FirstName: yup.string().required(INVALID_RESPONSE_MSG),
  applicant1LastName: yup.string().required(INVALID_RESPONSE_MSG),
  propertyValue: yup.string().required(INVALID_RESPONSE_MSG),
  equityLoanValue: yup.string().required(INVALID_RESPONSE_MSG),
  equityLoanDurationYears: yup
    .number()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG)
    .min(0, VALIDATION_DATE)
    .test(
      "total-duration-min",
      "Total duration must be at least 5 years",
      function (value) {
        const months = this.parent.equityLoanDurationMonths || 0;
        const totalMonths = (value || 0) * 12 + months;
        return totalMonths >= 60; // 5 years in months
      },
    )
    .test(
      "total-duration-max",
      "Total duration can not be more than 40 years 1 month",
      function (value) {
        const months = this.parent.equityLoanDurationMonths || 0;
        const totalMonths = (value || 0) * 12 + months;
        return totalMonths <= 481; // 40 years 1 month in months
      },
    ),
  equityLoanDurationMonths: yup
    .number()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG)
    .max(11, VALIDATION_DATE),
  creditIntermediaryFees: yup.string().required(INVALID_RESPONSE_MSG),
});
