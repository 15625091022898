import * as Yup from "yup";

import {
  checkTruthyValue,
  INVALID_RESPONSE_MSG,
  nullableNumberValidation,
  percentValueValidation,
  VALIDATION_DATE,
} from "utils/validation";

const validation: Yup.ObjectSchema<any> = Yup.object().shape({
  loan: Yup.object().shape({
    firstChargeMortgageValue: Yup.string().required(INVALID_RESPONSE_MSG),
    termOfFirstChargeLoanYears: Yup.number()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG)
      .min(0, VALIDATION_DATE)
      .test(
        "total-duration-min",
        "Total term must be at least 5 years",
        function (value) {
          const months = this.parent.termOfFirstChargeLoanMonths || 0;
          const totalMonths = (value || 0) * 12 + months;
          return totalMonths >= 60; // 5 years in months
        },
      )
      .test(
        "total-duration-max",
        "Total term can not be more than 40 years",
        function (value) {
          const months = this.parent.termOfFirstChargeLoanMonths || 0;
          const totalMonths = (value || 0) * 12 + months;
          return totalMonths <= 480; // 40 years
        },
      ),
    termOfFirstChargeLoanMonths: Yup.number()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG)
      .max(11, VALIDATION_DATE),
    isInterestRateFixedForAnInitialPeriod: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    termOfInitialInterestRatePeriodYears: Yup.number().when(
      "isInterestRateFixedForAnInitialPeriod",
      {
        is: (value: any) => checkTruthyValue(value),
        then: () =>
          Yup.number()
            .typeError(INVALID_RESPONSE_MSG)
            .required(INVALID_RESPONSE_MSG),
        otherwise: () => nullableNumberValidation,
      },
    ),
    firstChargeInitialInterestRatePercent:
      percentValueValidation.required(INVALID_RESPONSE_MSG),
    firstChargeSvrPercent:
      percentValueValidation.required(INVALID_RESPONSE_MSG),
    propertyPurchasePrice: Yup.string().required(INVALID_RESPONSE_MSG),
  }),
});

export default validation;
