import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";

import FormInputCurrency from "components/Form/Input/Currency";
import FormInputNumber from "components/Form/Input/Number";
import FormInputPercent from "components/Form/Input/Percent";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import FormInputTextArea from "components/Form/Input/TextArea";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { SELECT_OPTION_OTHER } from "pages/ApplicationForm/utils";
import { calcEquityLoanValue } from "utils/calcs";
import { checkFalseyValue, checkTruthyValue } from "utils/validation";
import { StepProps } from "../../types";
import { LABELS } from "./labels";
import {
  LoanDepositSources,
  LoanMortgagePurposes,
  LoanRepaymentPlanTypes,
} from "./options";

const STEP_KEY = "loan";

const LoanForm: React.FC<StepProps> = ({
  control,
  register,
  errors,
  setValue,
}) => {
  const loanDetail = useWatch({ name: STEP_KEY, control: control });

  useEffect(() => {
    const _equityLoanValue = calcEquityLoanValue(
      loanDetail?.propertyPurchasePrice,
    );
    setValue(`${STEP_KEY}.equityLoanAmountRequired`, _equityLoanValue);
  }, [loanDetail?.propertyPurchasePrice, setValue]);

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Loan" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <FormInputSelect
        id={`${STEP_KEY}.purposeOfMortgage`}
        label={LABELS.purposeOfMortgage}
        register={register}
        initSelect
        options={LoanMortgagePurposes}
        requiredField={false}
        error={errors?.[STEP_KEY]?.purposeOfMortgage?.message}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.depositAmount`}
        label={LABELS.depositAmount}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.depositAmount?.message}
        setValue={setValue}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.firstChargeMortgageValue`}
        label={LABELS.firstChargeMortgageValue}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.firstChargeMortgageValue?.message}
        setValue={setValue}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.valueOfFeeAddedToFirstChargeMortgage`}
        label={LABELS.valueOfFeeAddedToFirstChargeMortgage}
        register={register}
        requiredField={false}
        error={
          errors?.[STEP_KEY]?.valueOfFeeAddedToFirstChargeMortgage?.message
        }
        setValue={setValue}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.propertyPurchasePrice`}
        label={LABELS.propertyPurchasePrice}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.propertyPurchasePrice?.message}
        setValue={setValue}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.equityLoanAmountRequired`}
        label={LABELS.equityLoanAmountRequired}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.equityLoanAmountRequired?.message}
        setValue={setValue}
        disabled
        helperText="This value will be automatically calculated as 15% of the Property purchase price"
      />

      <FormInputRadio
        id={`${STEP_KEY}.isFirstChargeARepaymentMortgage`}
        label={LABELS.isFirstChargeARepaymentMortgage}
        register={register}
        requiredField={false}
        initValue={loanDetail?.isFirstChargeARepaymentMortgage}
        error={errors?.[STEP_KEY]?.isFirstChargeARepaymentMortgage?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.firstChargeLender`}
        label={LABELS.firstChargeLender}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.firstChargeLender?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.termOfFirstChargeLoanYears`}
        label={LABELS.termOfFirstChargeLoanYears}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.termOfFirstChargeLoanYears?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.termOfFirstChargeLoanMonths`}
        label={LABELS.termOfFirstChargeLoanMonths}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.termOfFirstChargeLoanMonths?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isInterestRateFixedForAnInitialPeriod`}
        label={LABELS.isInterestRateFixedForAnInitialPeriod}
        register={register}
        requiredField={false}
        initValue={loanDetail?.isInterestRateFixedForAnInitialPeriod}
        error={
          errors?.[STEP_KEY]?.isInterestRateFixedForAnInitialPeriod?.message
        }
      />

      {checkTruthyValue(loanDetail?.isInterestRateFixedForAnInitialPeriod) && (
        <FormInputNumber
          id={`${STEP_KEY}.termOfInitialInterestRatePeriodYears`}
          label={LABELS.termOfInitialInterestRatePeriodYears}
          register={register}
          requiredField={false}
          error={
            errors?.[STEP_KEY]?.termOfInitialInterestRatePeriodYears?.message
          }
        />
      )}

      <FormInputPercent
        id={`${STEP_KEY}.firstChargeInitialInterestRatePercent`}
        label={LABELS.firstChargeInitialInterestRatePercent}
        register={register}
        requiredField={false}
        error={
          errors?.[STEP_KEY]?.firstChargeInitialInterestRatePercent?.message
        }
      />

      <FormInputPercent
        id={`${STEP_KEY}.firstChargeSvrPercent`}
        label={LABELS.firstChargeSvrPercent}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.firstChargeSvrPercent?.message}
      />

      <FormInputSelect
        id={`${STEP_KEY}.equityLoanRepaymentPlan`}
        label={LABELS.equityLoanRepaymentPlan}
        register={register}
        initSelect
        options={LoanRepaymentPlanTypes}
        requiredField={false}
        error={errors?.[STEP_KEY]?.equityLoanRepaymentPlan?.message}
      />

      {loanDetail?.equityLoanRepaymentPlan === SELECT_OPTION_OTHER && (
        <FormInputTextArea
          id={`${STEP_KEY}.otherRepaymentPlan`}
          label={LABELS.otherRepaymentPlan}
          register={register}
          requiredField={false}
          error={errors?.[STEP_KEY]?.otherRepaymentPlan?.message}
        />
      )}

      <FormInputSelect
        id={`${STEP_KEY}.sourceOfDeposit`}
        label={LABELS.sourceOfDeposit}
        register={register}
        initSelect
        options={LoanDepositSources}
        requiredField={false}
        error={errors?.[STEP_KEY]?.sourceOfDeposit?.message}
      />

      {loanDetail?.sourceOfDeposit === SELECT_OPTION_OTHER && (
        <FormInputTextArea
          id={`${STEP_KEY}.otherSourceOfDeposit`}
          label={LABELS.otherSourceOfDeposit}
          register={register}
          requiredField={false}
          error={errors?.[STEP_KEY]?.otherSourceOfDeposit?.message}
        />
      )}

      <FormInputRadio
        id={`${STEP_KEY}.isAllDepositByOwnResource`}
        label={LABELS.isAllDepositByOwnResource}
        register={register}
        requiredField={false}
        initValue={loanDetail?.isAllDepositByOwnResource}
        error={errors?.[STEP_KEY]?.isAllDepositByOwnResource?.message}
      />

      {checkFalseyValue(loanDetail?.isAllDepositByOwnResource) && (
        <>
          <FormInputCurrency
            id={`${STEP_KEY}.otherDepositAmount`}
            label={LABELS.otherDepositAmount}
            register={register}
            requiredField={false}
            error={errors?.[STEP_KEY]?.otherDepositAmount?.message}
            setValue={setValue}
          />

          <FormInputText
            id={`${STEP_KEY}.otherDepositSource`}
            label={LABELS.otherDepositSource}
            register={register}
            requiredField={false}
            error={errors?.[STEP_KEY]?.otherDepositSource?.message}
          />
        </>
      )}

      <FormInputRadio
        id={`${STEP_KEY}.isAllOrPartDepositAGift`}
        label={LABELS.isAllOrPartDepositAGift}
        register={register}
        requiredField={false}
        initValue={loanDetail?.isAllOrPartDepositAGift}
        error={errors?.[STEP_KEY]?.isAllOrPartDepositAGift?.message}
      />

      {checkTruthyValue(loanDetail?.isAllOrPartDepositAGift) && (
        <>
          <FormInputRadio
            id={`${STEP_KEY}.isGiftFromCurrentOwner`}
            label={LABELS.isGiftFromCurrentOwner}
            register={register}
            requiredField={false}
            initValue={loanDetail?.isGiftFromCurrentOwner}
            error={errors?.[STEP_KEY]?.isGiftFromCurrentOwner?.message}
          />

          <FormInputRadio
            id={`${STEP_KEY}.willGifterLiveInPropertyAfterPurchase`}
            label={LABELS.willGifterLiveInPropertyAfterPurchase}
            register={register}
            requiredField={false}
            initValue={loanDetail?.willGifterLiveInPropertyAfterPurchase}
            error={
              errors?.[STEP_KEY]?.willGifterLiveInPropertyAfterPurchase?.message
            }
          />

          <FormInputRadio
            id={`${STEP_KEY}.willGifterTakeInterestInPropertyForGift`}
            label={LABELS.willGifterTakeInterestInPropertyForGift}
            register={register}
            requiredField={false}
            initValue={loanDetail?.willGifterTakeInterestInPropertyForGift}
            error={
              errors?.[STEP_KEY]?.willGifterTakeInterestInPropertyForGift
                ?.message
            }
          />

          <FormInputCurrency
            id={`${STEP_KEY}.amountOfGift`}
            label={LABELS.amountOfGift}
            register={register}
            requiredField={false}
            error={errors?.[STEP_KEY]?.amountOfGift?.message}
            setValue={setValue}
          />

          <FormInputTextArea
            id={`${STEP_KEY}.gifterDetail`}
            label={LABELS.gifterDetail}
            register={register}
            requiredField={false}
            error={errors?.[STEP_KEY]?.gifterDetail?.message}
          />
        </>
      )}
    </div>
  );
};

export default LoanForm;
